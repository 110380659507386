


.container {
  margin-top: 50px; 
  Display: flex;
  justify-content: center;
  align-items: center;
}
.phone {
  background-color: #36383F;
  border-radius: 40px;
  width: 300px;
  height: 600px;
  Display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 30px 50px 100px #85888C;
}
.content {
  min-height: 100vh;
  width: 100%;
  height: 91%;
  overflow: hidden;
}
nav {
  background-color: #111827;
  height: 65px;
}


#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 50;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #ffffff;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  position: absolute;
  width: 180px;
  min-height: 110vh;
  box-shadow: 0 0 10px #85888C;
  margin: -50px 0 0 -50px;
  padding-top: 125px;
  background-color: #1f2937;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}