@tailwind base;
@tailwind components;

.inputform {
    @apply h-7 my-2 border font-normal text-cyan-800 border-cyan-500 hover:border-cyan-800 hover:border-2 rounded-lg bg-transparent px-2 focus:outline-none focus:border-cyan-800 focus:ring-cyan-800;
}

.inputTicketForm {
    @apply h-8 my-2 border font-normal text-sky-900 border-cyan-300 hover:border-cyan-900 rounded-lg bg-transparent;
}

.selectform {
    @apply h-7 my-2 px-1 border font-normal text-cyan-800 border-cyan-500 hover:border-cyan-900 rounded-lg bg-transparent disabled:text-cyan-800 disabled:opacity-100;
}

.selectfilter {
    @apply h-7 border font-normal text-cyan-800 border-cyan-500 hover:border-gray-700 rounded-lg bg-transparent hover:border-2;
}

.textareaform {
    @apply my-2 border font-normal text-cyan-800 border-cyan-500 hover:border-cyan-800 hover:border-2 rounded-lg bg-transparent px-2 focus:outline-none focus:border-cyan-800 focus:ring-cyan-800;
}

.inputdate {
    @apply border h-7 font-normal text-cyan-800 border-cyan-500 hover:border-gray-700 hover:border-2 rounded-lg bg-transparent px-2 focus:outline-none focus:border-cyan-800 focus:ring-cyan-800;
}

.valErrorText {
    @apply text-red-400 font-medium;
}

@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #ffffff00;
    /* background: #f1f1f1; */
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}